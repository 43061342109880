import React from 'react'
import { graphql } from 'gatsby'

import { Fade, Layout, Preview, Previews } from '../components'

import instagram from '../assets/instagram.svg'
import { pickQuality } from '../../utils/pick-quality'

class Contact extends React.Component {
  title = 'Contact'

  componentWillMount() {
    const now = new Date()
    this.year = now.getFullYear()
  }

  render() {
    return (
      <Layout page={this}>
        <div className="contact">
          <div className="contact__bg">
            <Previews>
              <Preview
                className="h-full w-full"
                cover={`${this.props.data.page.cover.url}?auto=format&fit=min&w=1600`}
                src={pickQuality(this.props.data.page)}
                noPause
                autoPlay
                mobileVideo
              ></Preview>
            </Previews>
          </div>

          <div className="contact__content">
            <div className="contact__head">
              <Fade
                to={`/contact/awards/`}
                className="font-head uppercase text-16 tracking-wide"
              >
                Awards
              </Fade>
            </div>

            <div className="wrapper">
              <div className="lg:relative">
                <div className="contact__inner mx-auto pb-30 md:w-6/8 lg:w-8/10">
                  <h1 className="contact__title font-head text-24 tracking-bit pb-10 mt-40 mb-20">
                    About
                  </h1>
                  <div
                    className="text-14 leading-normal uppercase"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.page.about,
                    }}
                  ></div>

                  <h2 className="contact__title font-head text-24 tracking-bit pb-10 mt-40 mb-20">
                    Get in touch
                  </h2>

                  <div className="contact__wysiwyg lg:w-6/8">
                    <div className="flex flex-wrap -mx-20">
                      {[this.props.data.page.contacts[0]].map(
                        ({ id, description }) => (
                          <div
                            key={id}
                            className="px-20 w-full lg:w-1/2"
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></div>
                        )
                      )}
                    </div>
                    <div className="flex flex-wrap -mx-20 mt-40">
                      {this.props.data.page.contacts
                        .slice(1)
                        .map(({ id, description }) => (
                          <div
                            key={id}
                            className="px-20 w-full lg:w-1/3"
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></div>
                        ))}
                    </div>
                  </div>

                  <a
                    href={this.props.data.page.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact__instagram"
                  >
                    <img src={instagram} alt="" /> <span>Instagram</span>
                  </a>

                  <span className="contact__copyright">
                    © {this.year} - Miles Cinéma
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query ContactQuery {
    page: datoCmsContactpage {
      about
      contacts {
        id
        description
      }
      instagramUrl
      mp4Url
      mp4UrlSd
      cover {
        url
      }
    }
  }
`
